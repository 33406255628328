.allnonerow {
    display: flex;
    width: 100%;
    flex: 1 0 0;
    padding: 10px 15px;
    border-top: 1px solid #efefef;
}

.button-wrapper {
    display: flex;
    width: 100%;
    flex: 1 0 0;
    justify-content: space-between;

    > button:last-child {
        margin-left: 16px;
    }
}

.rcselect,
.rcselectitem {
    padding: 0 14px 0 6px;
    border-bottom: 1px solid white;

    .MuiList-padding {
        padding-top: 0 !important;
        padding-bottom: 0 !important;
    }

    &.MuiListItem-root.Mui-selected {
        background-color: #418aca !important;
        color: white;
    }

    &.userselect {
        border: none;
        padding-top: 8px;
    }

}

.rcselectitem:hover {
    &.MuiListItem-root {
        background-color: #d7e6f4 !important;
    }
}
