@font-face {
    font-family: "Mont-Bold";
    src: local("Mont-Bold"), url("Custom/Content/fonts/Mont-Bold.otf") format("truetype");
}

@font-face {
    font-family: "Mont-Light";
    src: local("Mont-Light"), url("Custom/Content/fonts/Mont-Light.otf") format("truetype");
}

@font-face {
    font-family: "Mont";
    src: local("Mont"), url("Custom/Content/fonts/Mont-Regular.otf") format("truetype");
}

@font-face {
    font-family: "Mont-Regular";
    src: local("Mont"), url("Custom/Content/fonts/Mont-Regular.otf") format("truetype");
}

@font-face {
    font-family: "Mont-Heavy";
    src: local("Mont-Heavy"), url("Custom/Content/fonts/Mont-Heavy.otf") format("truetype");
}

@font-face {
    font-family: "Mont-Black";
    src: local("Mont-Black"), url("Custom/Content/fonts/Mont-Black.otf") format("truetype");
}

html,
body {
    /* https://www.smashingmagazine.com/2016/05/fluid-typography/ */
    /* https://css-tricks.com/snippets/css/fluid-typography/ */
    /* font-size: calc([minimum size] + ([maximum size] - [minimum size]) * ((100vw - [minimum viewport width]) / ([maximum viewport width] - [minimum viewport width]))); */
    //font-size: calc(12px + (16 - 12) * ((100vw - 350px) / (1366 - 350)));
    /* font-size: calc(0.15em + 1vw); */
    margin: 0;
    /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    text-rendering: optimizeLegibility;
    background-color: white !important;
    height: 100%;

    font-family: "Mont-Regular", "Montserrat", "Roboto Condensed", "Helvetica", "Arial", "sans-serif";
}

#root {
    height: 100%;
}

/* @media only screen and (min-width: 2000px) {
    html,
    body {
        font-size: calc(16px + (24 - 16) * ((100vw - 2000px) / (2900 - 2000)));
    }
} */

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.App {
    text-align: center;
}

.App-logo {
    animation: App-logo-spin infinite 20s linear;
    height: 40vmin;
    pointer-events: none;
}

.App-header {
    background-color: #ffffff;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

.logoHeader {
    max-height: 55px;
}

.roofcarebreadcrumbcell {
    max-height: 52px;
    //height: 52px !important;
}

.layoutfooter {
    max-height: 25px;
    height: 25px !important;
}

.square {
    padding-top: 0 !important;
}

// Make a div square
.square:after {
    content: "";
    display: block;
    padding-bottom: 100%;
}

.editableInput-withlabel {
    .MuiInputLabel-formControl {
        text-align: left;
        font: normal normal bold 14px "Mont-Bold";
        letter-spacing: 0px;
        color: #040505;
        opacity: 1;
        height: 19px;
        margin-bottom: 5px;
    }

    input {
        margin-top: 5px;
        background-color: #ffffff !important;
        border: 1px solid #ededed;
        border-radius: 3px;
        opacity: 1;
        padding: 10px;

        text-align: left;
        font: normal normal normal 12px/26px "Mont-Regular";
        letter-spacing: 0px;
        color: #757474 !important;
        opacity: 1;
        font-weight: 300;
    }
}

.projectnote-addedit {
    display: inline-flex;
    align-self: flex-start;

    &.onitsown {
        margin-top: -15px;
        margin-left: 15px;
    }

    &.whiteout {
        color: black!important;

        .download-metric-svg {
            fill: black!important;
            color: black!important;
        }

        &.overdue {
            color: white!important;

            .download-metric-svg {
                fill: white!important;
                color: white!important;
            }
        }
    }
}

.marker-number {
    position: absolute;
    top: 12px;
    left: 20px;

    color: white;
}

.marker-number2 {
    position: absolute;
    top: 12px;
    left: 18px;

    color: white;
}

.overall-content,
#content {
    background: transparent linear-gradient(180deg, #565758 0%, #373a40 100%) 0% 0% no-repeat padding-box;
}

.disablewrapper {
    cursor: not-allowed;
    background-color: yellow;

    .disablegrid {
        background-color: orange;
        pointer-events: none;
        opacity: 0.2;
    }
}

.circle-icon {
    width: 24px;
    height: 24px;
    background: #337ab7;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0px 3px 6px #00000029;

    > img {
        max-width: 12px;
    }
}

.cursor-pointer {
    cursor: pointer;
}

.cross-icon {
    width: 24px;
}

.logoutOption {
    cursor: default;
}

.logoutOption:hover {
    cursor:pointer;
}


// Installation -> Attribute Diagram animation

.middlesection,
.legend,
.pie-legend 
{
    animation: fadein 2.5s;
   /* animation: pulse 2s infinite; */
}

.cancel-animation,
.remove-animation {
    animation: none !important;
}

.legend {
    svg text {
        font-size: 12px !important;
    }
}

.pie-legend {
    svg text {
        font-size: 8px !important;
    } 
}

.download-metric-wrapper {
    height: 20px;

}

.download-metric-svg {
    color: #FF9300;   
    cursor: default;
    text-decoration: none;
    padding-bottom: 5px;
}

.download-metric-svg:hover {
    cursor: pointer;
}

.alertaction-band,
.piechart-rightchart,
.cone-lines
{
    animation: fadein 1.5s;
   /* animation: pulse 2s infinite; */

    &.cancel-animation
    {
        animation: none !important;
        animation: 0 !important;
        color: transparent !important;
        stroke: transparent !important;
        fill: transparent !important;
    }
}

.alertaction-bandtext {
    animation: fadein 2.0s;
    animation: scaleX 1.0s ease-in-out;
}


.middlesection-band
{
    animation: scaleY 0.5s;
}

@keyframes fadein {
    0% { opacity: 0;}
    20% { opacity: 0.2;}
    40% { opacity: 0.4;}
    80% { opacity: 0.8;}
    100% {opacity: 1;}
}

@keyframes scaleY {
    0% { transform: scaleY(0.0); }
    10% { transform: scaleY(0.1);}
    20% { transform: scaleY(0.2);}
    30% { transform: scaleY(0.3);}
    40% { transform: scaleY(0.4);}
    50% { transform: scaleY(0.5);}
    80% { transform: scaleY(0.8);}
    90% { transform: scaleY(0.9);}
    100% {transform: scaleY(1); }
}

@keyframes scaleX {
/*     0% { transform: scaleX(0.0); }
    5% { transform: scaleX(0.05); }
    10% { transform: scaleX(0.1);}
    20% { transform: scaleX(0.2);}
    30% { transform: scaleX(0.3);}
    40% { transform: scaleX(0.4);}
    50% { transform: scaleX(0.5);}
    60% { transform: scaleX(0.6);}
    70% { transform: scaleX(0.7);}
    75% { transform: scaleX(0.75);}
    80% { transform: scaleX(0.8);}
    85% { transform: scaleX(0.85);}
    90% { transform: scaleX(0.9);}
    95% { transform: scaleX(0.95);}
    100% {transform: scaleX(1); } */

    0% { transform: scaleX(0.0); }
    5% { transform: scaleX(0.05); }
    10% { transform: scaleX(0.1);}
    20% { transform: scaleX(0.2);}
    30% { transform: scaleX(0.3);}
    40% { transform: scaleX(0.5);}
    50% { transform: scaleX(0.6);}
    60% { transform: scaleX(0.7);}
    70% { transform: scaleX(0.8);}
    75% { transform: scaleX(0.85);}
    80% { transform: scaleX(1);}
    85% { transform: scaleX(1.05);}
    90% { transform: scaleX(1.1);}
    95% { transform: scaleX(1.05);}
    100% {transform: scaleX(1); }

}


@keyframes pulse {
    0% {
    transform: scale(0.9);
    }
    40% {
    transform: scale(1) rotate(5deg);
    }
    80% {
    transform: scale(1) rotate(-5deg);
    }
    100% {
    transform: scale(0.9) rotate(0);
    }
}

// END Installation -> Attribute Diagram animation
/* @media screen and (min-resolution: 120dpi) { 
    body {zoom: 0.8;}
} */

.central-cell {
    display: flex;
    flex: 1 0 0; 
    justify-content: center;
    //background-color: #89b733;

    &.alert {
        width: 35px;
    }

    &.behaviour {
        width: 78px;
    }

    &.condition {
        width: 78px;
    }

}

.alerticon {
    &.tick 
    ,&.cross{
       font-size: 16px;   
    }
    margin-top: 3px;
    padding-left: 7px;
}

.tick {
    color: #61aa66;
    font-size: 18px;   
}

.cross {
    color: red;
    font-size: 18px;
    font-weight: 200;
}
